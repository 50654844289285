// @flow
import * as React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

import Ribbon from '../Ribbon/Ribbon'
import Markdown from '../Markdown/Markdown'

export type Props = {
  message?: string,
}

const AlertWrapper = styled(Ribbon)`
  margin: 0;
  padding: ${rem(16)} 0;
  background: white;
`

const AlertBox = styled.div`
  padding: ${rem(20)} ${rem(24)};
  background-color: #f7e6c4;
  border-radius: ${rem(8)};
`

function AlertBanner({ message }: Props) {
  if (!message) return null

  return (
    <div
      role="alert"
      // Add nosnippet to prevent Google from indexing
      // https://developers.google.com/search/docs/advanced/crawling/special-tags#inline-directives
      data-nosnippet=""
    >
      <AlertWrapper>
        <AlertBox>
          <Markdown noMargin fullWidth source={message} />
        </AlertBox>
      </AlertWrapper>
    </div>
  )
}

export default AlertBanner
