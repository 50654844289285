// @flow
import * as React from 'react'

const LogoutIcon = ({ style, ...rest }: Object) => (
  <svg
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    strokeWidth={2}
    style={{ cursor: 'pointer', ...style }}
    {...rest}
  >
    <g
      strokeLinecap="round"
      strokeLinejoin="round"
      stroke="currentColor"
      fill="none"
      strokeMiterlimit={10}
    >
      <path data-cap="butt" data-color="color-2" d="M9 12h12" />
      <path data-color="color-2" d="M17 16l4-4-4-4" />
      <path d="M19 4V1H5v22h14v-3" />
    </g>
  </svg>
)

export default LogoutIcon
