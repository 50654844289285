// @flow
import * as React from 'react'
import styled, { withTheme } from 'styled-components'
import { rem } from 'polished'

import { DEFAULT_THEME } from '../../styles/theme'

import type { ThemeViewModel } from '../../types/ThemeViewModel'
import type { LinkViewModel } from '../../types/LinkViewModel'

import Link from '../Link'
import FRHLogo from './logo.svg'
import FRHIcon from './frh-icon.svg'

type Props = {
  name?: string,
  link?: LinkViewModel,
  invert?: boolean,
  height?: string,
  width?: string,
  theme?: ThemeViewModel,
}

type State = {}

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 100%;
  width: 100%;
`
const Image = styled('img')`
  width: ${({ theme, width }) =>
    width ? width : theme === 'kids' ? '32px' : undefined};
  height: ${({ theme, height }) =>
    height ? height : theme === 'kids' ? '47px' : '100%'};
`

const LogoHeading = styled.p`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${rem(21)};
  font-weight: 900;
  margin-bottom: ${rem(18)}; /* Specific styling for the Header */
  white-space: nowrap;
  color: ${({ theme }) => theme.logoTextColor};
  line-height: 1.5;
  :hover {
    color: ${({ theme }) => theme.logoTextColorHover};
  }
`

class Logo extends React.Component<Props, State> {
  static displayName = 'Logo'
  static defaultProps = {
    link: {
      href: '/',
    },
    invert: false,
    theme: {
      name: DEFAULT_THEME,
    },
  }

  render() {
    const { link, name, invert, height, width, theme, ...rest } = this.props

    return (
      <LogoLink
        href={(link && link.href) || '#'}
        target={(link && link.target) || '_self'}
        label={name}
        {...rest}
      >
        {/*
          Use the logo as img src - could be inlined, but it makes sense to just load it async since
          it doesn't need any animations.
        */}
        {theme && theme.name === 'kids' ? (
          <>
            <Image
              src={FRHIcon}
              width={width}
              height={height}
              alt={name || 'Mig i Midten'}
              style={invert ? { filter: 'invert(100)' } : {}}
            />
            <LogoHeading>{theme.logoText || ''}</LogoHeading>
          </>
        ) : theme && theme.name === 'lgbt' ? (
          <>
            <Image
              src={FRHIcon}
              width={width}
              height={height}
              alt={name || 'LGBTFamilie'}
              style={invert ? { filter: 'invert(100)' } : {}}
            />
            <LogoHeading>{theme.logoText || ''}</LogoHeading>
          </>
        ) : (
          <Image
            src={FRHLogo}
            width={width}
            height={height}
            alt={name || 'Familieretshuset'}
            style={invert ? { filter: 'invert(100)' } : {}}
          />
        )}
      </LogoLink>
    )
  }
}

export default withTheme(Logo)
