// @flow
import * as React from 'react'
import styled from 'styled-components'

import type { LinkViewModel } from '../../../../types/LinkViewModel'
import type { LinkTargetTypes } from '../../../../types/enums/LinkTargetTypes'

import CtaLink from '../../../CtaLink/CtaLink'
import { Link, isExternalLink } from '../../../Link/Link'

type LinkProps = LinkViewModel & {
  children?: React.Node,
}

type Props = LinkViewModel & {
  children?: React.Node,
  isCtaLink?: boolean,
}

const CtaLinkWrapper = styled.div`
  margin-bottom: 1rem;
`

const MarkdownCtaLink = (props: LinkProps) => (
  <CtaLinkWrapper>
    <CtaLink {...props} />
  </CtaLinkWrapper>
)

const MarkdownNormalLink = (props: LinkProps) => {
  return (
    <Link {...props} underline={true}>
      {props.children}
    </Link>
  )
}

type LinkMetaInfo = { href?: string, target?: LinkTargetTypes }

const getMetaInfoFromHref = (value: string = ''): LinkMetaInfo => {
  let href = value
  let target: LinkTargetTypes = isExternalLink(href) ? '_blank' : '_self'

  const hrefSplit = href.split('|')

  if (hrefSplit.length > 1) {
    const _target = hrefSplit.splice(1, hrefSplit.length - 1).join('')

    switch (_target) {
      case 'blank':
        target = '_blank'
        break
      case 'self':
        target = '_self'
        break
      case 'parent':
        target = '_parent'
        break
      case 'top':
        target = '_top'
        break
      default:
        break
    }

    href = hrefSplit.join('|')
  }

  return {
    target,
    href,
  }
}

const LinkRenderer = (props: Props) => {
  const { isCtaLink, ...rest } = props
  const meta = getMetaInfoFromHref(props.href)
  const linkProps = { ...rest, ...meta }

  return isCtaLink ? (
    <MarkdownCtaLink {...linkProps} />
  ) : (
    <MarkdownNormalLink {...linkProps} />
  )
}

LinkRenderer.displayName = 'LinkRenderer'
LinkRenderer.defaultProps = {}

export default LinkRenderer
