// @flow
import * as React from 'react'
import styled from 'styled-components'
import Heading from '../../../Heading/Heading'
import { mediaOnly } from '../../../../styles/media'
import { AreaConsumer } from '../../../AreaContext/AreaContext'

type Props = {
  children?: React.Node,
  level: 1 | 2 | 3 | 4 | 5,
}

const SectionHeader = styled(Heading)`
  margin-top: ${p => (p.area === 'accordion' ? 40 : 80)}px;

  ${mediaOnly.xs`
    margin-top: ${p => (p.area === 'accordion' ? 20 : 40)}px;
  `};
`

function HeaderRenderer({ children, level }: Props) {
  /* Abuse the fact that we have 5 levels of headers, to render special blocks. */
  if (level === 1) {
    return (
      <AreaConsumer>
        {area => (
          <SectionHeader area={area} tag="h2" level={2}>
            {children}
          </SectionHeader>
        )}
      </AreaConsumer>
    )
  }
  if (level === 2) {
    return (
      <AreaConsumer>
        {area => (
          <SectionHeader area={area} tag="h2" level={3} marginBottom="small">
            {children}
          </SectionHeader>
        )}
      </AreaConsumer>
    )
  }

  return (
    <AreaConsumer>
      {area => (
        <SectionHeader area={area} tag="h3" level={4}>
          {children}
        </SectionHeader>
      )}
    </AreaConsumer>
  )
}

HeaderRenderer.displayName = 'HeaderRenderer'
HeaderRenderer.defaultProps = {}

export default HeaderRenderer
