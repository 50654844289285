// @flow
import * as React from 'react'
import styled from 'styled-components'
import qs from 'qs'
import Image from '../../../Image/Image'
import { mediaOnly } from '../../../../styles/media'

type Props = {
  src: string,
  title?: string,
  alt?: string,
}

type State = {}

const Wrapper = styled.span`
  display: block;
  margin: 80px 0;
  max-width: ${p => p.maxWidth};

  ${mediaOnly.xs`
    margin: 40px 0;
  `};
`

const imageSizes = {
  default: {
    maxWidth: '1440px',
  },
  main: {
    maxWidth: '880px',
  },
}

class ImageRenderer extends React.PureComponent<Props, State> {
  static displayName = 'ImageRenderer'
  static defaultProps = {}

  render() {
    const { src, alt } = this.props

    if (!src) return null
    const [imgSrc, attr] = src.split('?', 2)
    const attrs = qs.parse(attr)
    const width = parseInt(attrs.width, 10)
    const height = parseInt(attrs.height, 10)
    const noSizes = !width || !height

    return (
      <Wrapper maxWidth={width > 0 && !noSizes ? `${width}px` : '100%'}>
        <Image
          src={imgSrc}
          // Hardcoded values for src without w&h
          width={width || 200}
          height={height || 130}
          contain={noSizes}
          alt={alt}
          ratio="original"
          sizes={imageSizes.default}
        />
      </Wrapper>
    )
  }
}

export default ImageRenderer
