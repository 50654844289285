// @flow
import * as React from 'react'
import styled from 'styled-components'
import BaseButton from '../../../components/Button/BaseButton'
import RemoveIcon from '../../../icons/RemoveIcon'
import BurgerIcon from '../../../icons/BurgerIcon'

type Props = {
  icon: 'close' | 'burger',
  isOpen?: boolean,
  size: number,
}

const Button = styled(BaseButton)`
  background: none;
  color: currentColor;
  outline-offset: 0;
  display: flex;
  flex: 0 0 45px;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
`

function MenuButton({ icon, isOpen, size, ...rest }: Props) {
  let inner
  if (icon === 'close') inner = <RemoveIcon />
  else if (icon === 'burger')
    inner = <BurgerIcon width={`${size}`} open={isOpen} />

  return (
    <Button isOpen={isOpen} aria-label={'Menu button'} {...rest}>
      {inner}
    </Button>
  )
}

MenuButton.displayName = 'MenuButton'
MenuButton.defaultProps = {
  isOpen: false,
  size: 28,
}

export default MenuButton
