// @flow
import * as React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { effects } from '../../styles/theme'
import CtaLink from '../CtaLink/CtaLink'
import { mediaOnly, mediaQuery } from '../../styles/media'
import { animated, Transition } from 'react-spring/renderprops.cjs'
import { presets } from '../../styles/animations'
import type { LinkViewModel } from '../../types/LinkViewModel'

type Props = {
  id: string,
  labeledById: string,
  expanded: boolean,
  isResting: boolean,
  itemBodyStyle: Object,
  isArticle?: boolean,
  cta?: LinkViewModel,
  children: React.Node,
  hoverShadow?: boolean,
  onResting?: () => void,
  onStart?: () => void,
  invert?: boolean,
  isMenu?: boolean,
}

const Container = styled(animated.div)`
  color: ${({ theme }) => theme.text};
  overflow: hidden;
  border-bottom: 1px solid transparent;
  ${({ expanded }) =>
    !expanded
      ? `border-bottom-color: #E3E3E3;`
      : 'border-bottom-color: transparent'};

  ${mediaQuery.sm`
      overflow: unset;
    `}

  p {
    font-weight: 300;
  }
`

const Wrapper = styled.div`
  background-color: ${({ theme, isMenu }) =>
    isMenu ? theme.accordionBodyStyle : 'transparent'};

  //border-top: ${({ theme }) =>
    effects(theme).borderPositive}; //TODO: Check if it has side-effects
`

const FooterLink = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: ${rem(30)};

  ${mediaOnly.xs`
    padding-right: 0;
    margin-right: -12px;
  `};
`

function AccordionItemBody(props: Props) {
  const {
    expanded,
    isResting,
    id,
    labeledById,
    children,
    cta,
    itemBodyStyle,
    hoverShadow,
    invert,
    isMenu,
  } = props

  const itemBodyStyling = { ...itemBodyStyle } || {}

  if (hoverShadow && itemBodyStyling.paddingLeft === undefined) {
    itemBodyStyling.paddingLeft = rem(24)
  }

  if (hoverShadow && itemBodyStyling.paddingRight === undefined) {
    itemBodyStyling.paddingRight = rem(24)
  }

  return (
    <Wrapper
      id={id}
      aria-hidden={!expanded}
      aria-labelledby={labeledById}
      // role="tabpanel"
      isMenu={isMenu}
    >
      <Transition
        native
        unique
        items={expanded || []}
        config={presets.stiff}
        onStart={props.onStart}
        onRest={props.onResting}
        from={{ height: 0, opacity: 0, transform: 'translateY(-20px)' }}
        enter={{
          opacity: 1,
          transform: 'translateY(0)',
          height: 'auto',
        }}
        leave={{
          opacity: 0,
          height: 0,
        }}
      >
        {() => style => (
          <Container
            style={{
              opacity: style.opacity,
              transform: style.transform,
              height: style.height.interpolate(height =>
                isResting ? 'auto' : height,
              ),
              ...itemBodyStyling,
            }}
          >
            {children}
            {cta && (
              <FooterLink>
                <CtaLink
                  invert={invert}
                  size="small"
                  tabIndex={expanded ? '0' : '-1'}
                  {...cta}
                />
              </FooterLink>
            )}
          </Container>
        )}
      </Transition>
    </Wrapper>
  )
}
AccordionItemBody.displayName = 'AccordionItemBody'

// $FlowFixMe
export default React.memo(AccordionItemBody)
