// @flow
import * as React from 'react'
import List from '../../../List/List'
import { MaxWidth } from '../../markdown-styles'

type Props = {
  children?: React.Node,
  ordered: boolean,
}

const ArticleList = MaxWidth.withComponent(List)

function ListRenderer({ children, ordered }: Props) {
  return <ArticleList ordered={ordered}>{children}</ArticleList>
}

ListRenderer.displayName = 'ListRenderer'
ListRenderer.defaultProps = {
  ordered: false,
}

export default ListRenderer
