// @flow
import * as React from 'react'
import styled from 'styled-components'
import Paragraph from '../../../Paragraph/Paragraph'
import { getLineHeight } from '../../../../styles/style-helpers'
import LinkRenderer from '../LinkRenderer/LinkRenderer'
import { color } from '../../../../styles/theme'

type Props = {
  children?: React.Node,
}

const StyledParagraph = styled(Paragraph)`
  line-height: ${getLineHeight('normal')};
  & > a {
    color: inherit;
  }
`

const isImageRendere = item =>
  item.type && item.type.displayName === 'ImageRenderer'
const isLinkRendere = item =>
  item.type && item.type.displayName === 'LinkRenderer'

function ParagraphRenderer({ children }: Props) {
  const childs = React.Children.toArray(children).reduce((acc, val, index) => {
    if (isImageRendere(val)) {
      // Images are always rendered in their own "paragraph"
      acc.push(val)
    } else {
      // All other children are added to the list
      if (!acc.length || !Array.isArray(acc[acc.length - 1])) {
        acc.push([])
      }
      acc[acc.length - 1].push(val)
    }
    return acc
  }, [])

  return (
    <React.Fragment>
      {childs.map((child, index) => {
        if (!Array.isArray(child) && isImageRendere(child)) {
          return child
        }

        if (child.every(isLinkRendere)) {
          return child.map((link, key) => (
            <LinkRenderer
              {...link.props}
              key={`Markdown-CTALink-${index}_${key}`}
              isCtaLink
            />
          ))
        }
        return (
          <StyledParagraph maxWidth marginBottom noBreaks key={index}>
            {child}
          </StyledParagraph>
        )
      })}
    </React.Fragment>
  )
}

ParagraphRenderer.displayName = 'ParagraphRenderer'
ParagraphRenderer.defaultProps = {}

export default ParagraphRenderer
