// @flow
import React, { useState, useEffect } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { rem, rgba } from 'polished'
import { concat, filter } from 'lodash'

import type { MainMenuItem } from '../../../types/MainMenuItem'

import BaseButton from '../../../components/Button/BaseButton'
import { Link } from '../../../components/Link/Link'
import Heading from '../../../components/Heading/Heading'
import Ribbon from '../../../components/Ribbon/Ribbon'
import RemoveIcon from '../../../icons/RemoveIcon'
import MenuColumnItem, {
  Separator,
} from '../../../components/MenuColumnItem/MenuColumnItem'
import { color, effects } from '../../../styles/theme'

type Props = {
  menuItem?: MainMenuItem,
  onClose?: (shouldFocus: boolean) => void,
  closeLabel?: string,
  theme?: string,
  id?: string,
  ariaControlledBy?: string,
  isActive?: boolean,
}

function HeaderDesktopOverlay({
  menuItem,
  onClose,
  closeLabel,
  theme,
  id,
  ariaControlledBy,
  isActive,
}: Props) {
  const [closeHover, setCloseHover] = useState(false)
  const activeTheme = React.useContext(ThemeContext)

  const handleCloseClick = e => {
    setCloseHover(false)
    // Differentiating between mouse & keyboard onClick
    if (e.pageX && e.pageY) {
      onClose && onClose(false)
    } else {
      onClose && onClose(true)
    }
  }

  const handleEsc = e => {
    e.stopPropagation()
    if (e.keyCode === 27) {
      onClose && isActive && onClose(true)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleEsc)

    return () => window.removeEventListener('keydown', handleEsc)
  })

  const diff = getDiff(theme)
  const top = getTop(theme)

  const closeStyle = {
    color: closeHover
      ? activeTheme && activeTheme.accent
      : activeTheme && activeTheme.primary,
    transition: effects().linkHoverTransition,
  }

  if (!menuItem) return null

  const allMenuItems = concat(
    menuItem.column1 || [],
    menuItem.column2 || [],
    menuItem.column3 || [],
    menuItem.column4 || [],
  )

  const submenuItems = filter(allMenuItems, item => !item.isFooterItem)
  const footerItems = filter(allMenuItems, item => item.isFooterItem)

  return (
    <OverlayWrapper
      top={diff}
      id={id}
      aria-expanded
      aria-labelledby={ariaControlledBy}
      isActive={!isActive}
    >
      <Ribbon marginBottom={false}>
        <OuterContainer height={top + 1}>
          <div>
            <UtilContainer>
              <BaseButton
                onMouseEnter={() => setCloseHover(true)}
                onMouseLeave={() => setCloseHover(false)}
                onClick={e => handleCloseClick(e)}
              >
                <CloseIcon>
                  <ButtonText style={closeStyle}>{closeLabel}</ButtonText>
                  <div>
                    <RemoveIcon
                      style={{
                        ...closeStyle,
                        height: '1.5em',
                        width: '1.5em',
                      }}
                    />
                  </div>
                </CloseIcon>
              </BaseButton>
            </UtilContainer>
            {menuItem && (
              <MenuWrapper>
                {submenuItems.map((item, idx) => (
                  <MenuColumnItem
                    key={`${item.title}-${idx}`}
                    withBottomBorder
                    {...item}
                  />
                ))}
                {/* make sure items spaces correctly when two menuitems in last row */}
                {submenuItems.length % 3 === 2 && <MenuColumnItem />}
              </MenuWrapper>
            )}
          </div>
          <Footer>
            {footerItems.map((item, idx) => (
              <FooterItem key={`${item.title}-${idx}`}>
                <FooterLink tag="h3" level={4}>
                  <Link {...item.title}>{item.title.label}</Link>
                </FooterLink>
                {idx + 1 !== footerItems.length && <Separator>|</Separator>}
              </FooterItem>
            ))}
          </Footer>
        </OuterContainer>
      </Ribbon>
    </OverlayWrapper>
  )
}

export default HeaderDesktopOverlay

function getDiff(theme?: string) {
  if (typeof window === 'undefined') return 0
  const scrollY = window.scrollY || window.pageYOffset

  if (theme === 'kids' || theme === 'lgbt') {
    return 80
  }
  if (scrollY <= 40) {
    return 40 - scrollY + 40 // works
  }
  return 80
}

function getTop(theme?: string) {
  if (typeof window === 'undefined') return 0
  const scrollY = window.scrollY || window.pageYOffset

  if (theme === 'kids' || theme === 'lgbt') {
    return 80
  }
  if (scrollY === 0) {
    return 120
  }
  if (scrollY <= 40) {
    return 100 - scrollY
  }
  return 80
}

const OverlayWrapper = styled.div`
  margin: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: ${({ top }) => rem(top)};
  overflow-y: auto;
  background-color: ${color.white};
  border-top: ${({ theme }) => `1px solid ${rgba(theme.primary, 0.08)}`};
  z-index: 1;
  visibility: ${({ isActive }) => (isActive ? 'hidden' : 'visible')};
`

const MenuWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: space-between;
  height: calc(100vh - ${({ height }) => rem(height)});
`

const UtilContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  cursor: pointer;
  padding-top: ${rem(28)};
  align-items: center;
  margin-bottom: ${rem(20)};
`

const ButtonText = styled.div`
  letter-spacing: ${rem(0.2)};
  font-weight: 500;
  margin-right: ${rem(8)};
  align-self: center;
  margin-bottom: ${rem(3)};
`

const CloseIcon = styled.div`
  display: flex;
`

const Footer = styled.div`
  display: flex;
  margin-bottom: ${rem(24)};
  align-self: center;
  align-items: flex-end;
  height: 100%;
`

const FooterLink = styled(Heading)`
  font-weight: 900;
  display: inline;
`
const FooterItem = styled.div`
  display: flex;
`
