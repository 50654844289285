// @flow
import * as React from 'react'

const SearchIcon = ({ style, ...rest }: Object) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7667 7.1C14.7667 8.7 14.2667 10.1 13.3667 11.3L16.3667 14.4C16.7667 14.8 16.7667 15.4 16.3667 15.8C16.1667 16 15.8667 16.1 15.6667 16.1C15.4667 16.1 15.1667 16 14.9667 15.8L11.9667 12.8C10.8667 13.7 9.36675 14.2 7.76675 14.2C3.86675 14.2 0.666748 11 0.666748 7.1C0.666748 3.2 3.86675 0 7.76675 0C11.6667 0 14.7667 3.2 14.7667 7.1ZM2.66675 7.1C2.66675 9.9 4.96675 12.1 7.76675 12.1C10.5667 12.1 12.8667 9.9 12.8667 7.1C12.8667 4.3 10.5667 2 7.76675 2C4.96675 2 2.66675 4.3 2.66675 7.1Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SearchIcon
