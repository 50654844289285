// @flow

import React, { useState, useEffect, useRef } from 'react'
import { useAtom } from 'jotai'
import {
  formSessionIdAtom,
  formSessionIsReceiptStepAtom,
  validFormVersionAtom,
} from '../../App/Atoms'
import { rem } from 'polished'
import styled from 'styled-components'
import throttle from 'lodash/throttle'

import Ribbon from '../Ribbon/Ribbon'
import Button from '../Button/Button'
import { Link } from '../Link/Link'
import ModalComponent from '../ModalComponent/ModalComponent'
import FormOverlay from '../Form/FormOverlay/FormOverlay'

import type { PersonInfoViewModel } from '../../types/PersonInfoViewModel'
import type { OverlayViewModel } from '../../types/OverlayViewModel'
import type { ServiceLogoutViewModel } from '../../types/ServiceLogoutViewModel'

import { mediaOnly } from '../../styles/media'
import { durations } from '../../styles/animations'

export type Props = {
  logout?: ServiceLogoutViewModel,
  personInfo: PersonInfoViewModel,
  description?: string,
  closeLabel?: string,
  saveButtonLabel?: string,
  cancelButtonLabel?: string,
  logoutButtonLabel?: string,
  saveOverlay: OverlayViewModel,
  deleteOverlay: OverlayViewModel,
  userCanSaveDelete?: boolean,
}

type ShowOverlayTypes = 'none' | 'save' | 'delete'

const PowerOfAttorneyBannerWrapper = styled(Ribbon)`
  background: #e1f0f1;
  margin: 0;
  padding: ${rem(16)} 0;
  min-height: ${rem(72)};

  ${mediaOnly.xs`
    min-height: ${rem(64)};
  `}
`

const PowerOfAttorneyBannerInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #e1f0f1;
  color: ${({ theme }) => theme.primary};
`

const StyledText = styled.div`
  font-size: ${rem(20)};
  line-height: 1.5;

  ${mediaOnly.xs`
    font-size: ${rem(16)};
  `}
`

const Actions = styled.div`
  background: #e1f0f1;
  position: relative;
  margin: ${rem(12)} 0;

  ${mediaOnly.xs`
    max-height: ${props => (props.visible ? '' : '0')};
    margin: ${props => (props.visible ? `${rem(12)} 0` : '0')};
    opacity: ${props => (props.visible ? '1' : '0')};
    transition: all 0.1s;
  `};
`

const StyledButton = styled.button`
  padding: 0;
  border: 0;
  text-decoration: underline;
  cursor: pointer;
  margin-right: ${rem(24)};
  color: ${({ theme }) => theme.primary};
`

const StyledButtonLink = styled(Link)`
  border-width: ${rem(2)};
  border-style: solid;
  color: ${({ theme }) => theme.buttonSecondaryColor};
  border-color: ${({ theme }) => theme.buttonSecondaryColor};
  min-width: ${rem(110)};
  text-align: center;
  font-size: 0.875rem;
  font-weight: bold;
  letter-spacing: 0.5px;
  padding: ${rem(12)} ${rem(32)} ${rem(11)};
  border-radius: ${rem(25)};
  transition: all ${durations.fast} ease-out;

  &:hover {
    color: ${({ theme }) => theme.buttonPrimaryColor};
    border-color: ${({ theme }) => theme.buttonSecondaryColor};
    background-color: ${({ theme }) => theme.buttonSecondaryColor};
  }
`

const StyledCancelButton = styled(Button)`
  margin-right: 16px;

  ${mediaOnly.xs`
  margin-bottom: 32px;
  margin-right: 0px;
  `}
`

export default function PowerOfAttorneyBanner({
  logout,
  personInfo,
  description = 'Du er i gang med at ansøge på vegne af: ',
  closeLabel = 'Luk',
  saveButtonLabel,
  cancelButtonLabel,
  logoutButtonLabel = 'Log ud',
  saveOverlay,
  deleteOverlay,
  userCanSaveDelete,
}: Props) {
  const [visible, setVisible] = useState(true)
  const [showOverlay, setShowOverlay] = useState(false)
  const [overlayType, setOverlayType] = useState<ShowOverlayTypes>('none')
  const [formSessionId] = useAtom(formSessionIdAtom)
  const [isReceiptStep] = useAtom(formSessionIsReceiptStepAtom)
  const [validFormVersion] = useAtom(validFormVersionAtom)

  const prevScrollPos = useRef(0)
  const sessionId = formSessionId || ''

  const handleScroll = throttle(() => {
    const currentScrollPos = window.pageYOffset

    if (currentScrollPos < 20) {
      setVisible(true)
    } else if (
      prevScrollPos.current > currentScrollPos &&
      prevScrollPos.current - currentScrollPos > 100
    ) {
      setVisible(true)
    } else if (
      prevScrollPos.current < currentScrollPos &&
      currentScrollPos > 100 &&
      currentScrollPos - prevScrollPos.current > 100
    ) {
      setVisible(false)
    }

    prevScrollPos.current = currentScrollPos
  }, 400)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [setVisible])

  if (!personInfo) return null

  const handleClose = () => {
    setShowOverlay(false)
  }

  const handleSaveOverlay = () => {
    setOverlayType('save')
    setShowOverlay(true)
  }

  const handleDeleteOverlay = () => {
    setOverlayType('delete')
    setShowOverlay(true)
  }

  const searchParamsSaveForm = new URLSearchParams({
    sessionId,
    deleteActiveForm: 'false',
  })

  const searchParamsDeleteForm = new URLSearchParams({
    sessionId,
    deleteActiveForm: 'true',
  })

  const hrefDelete =
    logout &&
    logout.logoutLink &&
    `${logout.logoutLink}?${searchParamsDeleteForm.toString()}`

  const hrefSave =
    logout &&
    logout.logoutLink &&
    `${logout.logoutLink}?${searchParamsSaveForm.toString()}`

  return (
    <div role="alert" data-nosnippet="" aria-label="fuldmagt-banner">
      <PowerOfAttorneyBannerWrapper visible={visible}>
        <PowerOfAttorneyBannerInner>
          <StyledText>
            {description}
            <strong>
              {personInfo.name} - {personInfo.procurationId}
            </strong>
          </StyledText>
        </PowerOfAttorneyBannerInner>
        <Actions visible={visible}>
          {userCanSaveDelete && !isReceiptStep ? (
            <>
              {validFormVersion && (
                <StyledButton onClick={handleSaveOverlay}>
                  {saveButtonLabel}
                </StyledButton>
              )}
              <StyledButton onClick={handleDeleteOverlay}>
                {cancelButtonLabel}
              </StyledButton>
            </>
          ) : (
            logout && (
              <StyledButton
                onClick={() => {
                  if (window.confirm(logout.confirmMessage)) {
                    window.open(logout.logoutLink, '_self')
                  }
                }}
              >
                {logoutButtonLabel}
              </StyledButton>
            )
          )}
        </Actions>
      </PowerOfAttorneyBannerWrapper>
      <ModalComponent
        open={showOverlay}
        closeText={closeLabel}
        onClose={handleClose}
      >
        {overlayType === 'save' && (
          <FormOverlay
            handleClose={handleClose}
            title={saveOverlay.title}
            src={saveOverlay.src}
          >
            <StyledCancelButton type="normal" onClick={handleClose}>
              {saveOverlay.secondaryLabel}
            </StyledCancelButton>
            <StyledButtonLink href={hrefSave} target="_self">
              {saveOverlay.primaryLabel}
            </StyledButtonLink>
          </FormOverlay>
        )}
        {overlayType === 'delete' && (
          <FormOverlay
            handleClose={handleClose}
            title={deleteOverlay.title}
            src={deleteOverlay.src}
          >
            {validFormVersion && (
              <StyledCancelButton type="normal" onClick={handleClose}>
                {deleteOverlay.secondaryLabel}
              </StyledCancelButton>
            )}
            <StyledButtonLink href={hrefDelete} target="_self">
              {deleteOverlay.primaryLabel}
            </StyledButtonLink>
          </FormOverlay>
        )}
      </ModalComponent>
    </div>
  )
}
