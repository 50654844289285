// @flow
import * as React from 'react'

const ZoomIcon = ({ style, ...rest }: Object) => (
  <svg
    viewBox="0 0 24 24"
    {...rest}
    width="1em"
    height="1em"
    stroke="currentColor"
    style={{ cursor: 'pointer', ...style }}
  >
    <g
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      fill="none"
      strokeMiterlimit={10}
    >
      <path data-color="color-2" d="M22 22l-6.344-6.344" />
      <circle cx={10} cy={10} r={8} />
    </g>
  </svg>
)

export default ZoomIcon
