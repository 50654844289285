// @flow
import * as React from 'react'
import styled from 'styled-components'

const SkipToContentLinkStyle = styled.div`
  a {
    position: absolute;
    top: -0.125rem;
    left: -9999px;
    z-index: 999;
    padding: 0.75rem 2rem 0.6875rem;
    opacity: 0;
    text-decoration: none;
    font-size: 0.875rem;
    font-weight: bold;
    letter-spacing: 0.5px;
    border: 0.125rem solid #003851;
    border-radius: 0 0 1.5625rem 1.5625rem;
    color: #003851;

    &:focus {
      left: 50%;
      transform: translateX(-50%);
      opacity: 1;
    }
  }
`

export default function SkipToContentLink() {
  return (
    <React.Fragment>
      <SkipToContentLinkStyle>
        <a href="#main">Skip to Content</a>
      </SkipToContentLinkStyle>
    </React.Fragment>
  )
}
