// @flow
import * as React from 'react'
import styled from 'styled-components'
import BaseButton from '../Button/BaseButton'
import type { LinkViewModel } from '../../types/LinkViewModel'
import { secondaryFontFamily } from '../../styles/theme'
import type { ButtonState } from '../Button/BaseButton'
import { getFontSize } from '../../styles/style-helpers'
import { durations } from '../../styles/animations'

type Props = LinkViewModel & {
  children?: React.Node,
  /** Always render as anchor tag, don't fall back to button */
  isAnchor?: boolean,
  disabled?: boolean,
  size: 'small' | 'normal',
  invert?: boolean,
}

type AlignProps = {
  align: 'left' | 'right',
}

type State = {}

const Base = styled(BaseButton)`
  && {
    display: inline-block;
    font-size: ${(props: Props) => getFontSize(props.size)};
    font-family: ${secondaryFontFamily};
    text-decoration: underline;
    line-height: 1.25;
    text-align: left;
    max-width: 100%;
    transform: translateX(-0.5rem);
  }
`

const Container = styled.div`
  position: relative;
  padding: 0.5rem;
  color: ${({ theme, invert }) => (invert ? theme.textInvert : theme.primary)};
  :hover {
    color: ${({ theme }) => theme.accent};
  }
`

const Label = styled.span`
  transition: color ${durations.fast} ease-out;
  margin-left: ${(p: AlignProps) => (p.align === 'left' ? '22px' : null)};
  margin-right: ${(p: AlignProps) => (p.align === 'right' ? '22px' : null)};
`

class CtaLink extends React.Component<Props, State> {
  static displayName = 'CtaLink'
  static defaultProps = {
    size: 'normal',
  }

  renderInner = ({ hovering }: ButtonState) => {
    const { children, label, invert } = this.props
    return (
      <Container hovering={hovering} invert={invert}>
        <Label hovering={hovering}>{children || label}</Label>
      </Container>
    )
  }

  render() {
    const { children, ...rest } = this.props
    return <Base {...rest}>{this.renderInner}</Base>
  }
}

export default CtaLink
