// @flow
import * as React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

import { color } from '../../styles/theme'

type Props = {
  children?: React.Node,
  ordered?: boolean,
}

const Base = styled.ul`
  color: inherit;
  //color: ${({ theme }) => theme.text};
  font-size: ${({ theme }) => rem(theme.bodyFontSize)};
  font-weight: ${({ theme }) => theme.bodyFontWeight};
  line-height: 1.5;
  padding: 0 0 0 1.5rem;
  margin-bottom: 2.5rem;
  list-style: none;

  > li {
    margin-bottom: 0.5rem;
    list-style-type: none;
    position: relative;
  }

  li > ul,
  li > ol {
    margin: 0.5rem 0 0;
  }
`
//TODO
const UnorderedList = styled(Base)`
  ${({ theme }) =>
    theme.name === 'kids' || theme.name === 'lgbt'
      ? `
    li::before {
      content: ''; /* The unicode for • character */
      position: absolute;
      border: 3px solid ${color.yellow};
      border-radius: 50%;
      width: ${rem(12)};
      height: ${rem(12)};
      left: ${rem(-20)};
      top: ${rem(6)};
    }`
      : `li::before {
      content: '\\2022'; /* The unicode for • character */
      position: absolute;
      left: -1em;
      font-size: 1em;
    }`}
`

const OrderedList = styled(Base.withComponent('ol'))`
  counter-reset: section;
  li::before {
    counter-increment: section;
    content: counter(section) '.';
    font-weight: 500;
    position: absolute;
    left: -1em;
    font-size: 1rem;
  }
`

function List({ children, ordered, ...props }: Props) {
  const Comp = ordered ? OrderedList : UnorderedList
  return <Comp {...props}>{children}</Comp>
}

List.displayName = 'List'
List.defaultProps = {}

export default List
