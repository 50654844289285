// @flow
import * as React from 'react'
import styled from 'styled-components'
import Button from '../../Button/Button'
import Markdown from '../../Markdown/Markdown'
import { mediaOnly } from '../../../styles/media'
import { color } from '../../../styles/theme'
import { rem } from 'polished'

import type { OverlayViewModel } from '../../../types/OverlayViewModel'

const StyledOverlayHeader = styled.h2`
  color: ${({ theme }) => theme.primary};
  ${mediaOnly.xs`
    margin-top: 4rem;
  `}
`

const StyledMarkdown = styled(Markdown)`
  margin: ${rem(24)} 0;
`

const StyledButtonWrapper = styled.div`
  display: flex;
  margin-top: 3rem;
  ${mediaOnly.xs`
    flex-direction: column;
    justify-content: space-between;
  `}
`

const StyledCancelButton = styled(Button)`
  margin-right: 16px;
  ${mediaOnly.xs`
  margin-bottom: 32px;
  margin-right: 0px;
  `}
`

type FormOverlayProps = OverlayViewModel & {
  handlePrimaryAction?: () => void,
  handleSecondaryAction?: () => void,
  children?: React.Node,
  handleClose?: () => void,
}

const FormOverlay = ({
  handleClose,
  title,
  src,
  primaryLabel,
  secondaryLabel,
  handlePrimaryAction,
  handleSecondaryAction,
  children,
}: FormOverlayProps) => {
  return (
    <>
      <StyledOverlayHeader>{title}</StyledOverlayHeader>
      <StyledMarkdown source={src}></StyledMarkdown>
      <StyledButtonWrapper>
        {secondaryLabel && (
          <StyledCancelButton
            type="normal"
            onClick={
              handleSecondaryAction ? handleSecondaryAction : handleClose
            }
          >
            {secondaryLabel}
          </StyledCancelButton>
        )}
        {primaryLabel && handlePrimaryAction && (
          <Button type="normal" onClick={handlePrimaryAction}>
            {primaryLabel}
          </Button>
        )}
        {children}
      </StyledButtonWrapper>
    </>
  )
}

export default FormOverlay
