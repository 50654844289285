// @flow
import * as React from 'react'
import ReactMarkdown from 'react-markdown'

import type { MarkdownViewModel } from '../../types/MarkdownViewModel'

import HeaderRenderer from './renderers/HeaderRenderer/HeaderRenderer'
import ParagraphRenderer from './renderers/ParagraphRenderer/ParagraphRenderer'
import LinkRenderer from './renderers/LinkRenderer/LinkRenderer'
import ImageRenderer from './renderers/ImageRenderer/ImageRenderer'
import ListRenderer from './renderers/ListRenderer/ListRenderer'
import TextRenderer from './renderers/TextRenderer/TextRenderer'

import styled, { css, ThemeContext } from 'styled-components'
import { mediaOnly } from '../../styles/media'
import { clearMargin } from '../../styles/style-helpers'

type Props = MarkdownViewModel & { invert?: boolean }

const RENDERERS = {
  ...ReactMarkdown.renderers,
  heading: HeaderRenderer,
  paragraph: ParagraphRenderer,
  link: LinkRenderer,
  image: ImageRenderer,
  list: ListRenderer,
  text: TextRenderer,
}

const normalStyle = css`
  margin: 80px 0;
  ${mediaOnly.xs`
    margin: 40px 0;
  `};
`

const fullWidthStyle = css`
  & p {
    max-width: 100%;
  }
`

const StyledMarkdown = styled(ReactMarkdown)`
  word-break: break-word;
  ${p => (p.noMargin ? clearMargin() : normalStyle)};
  ${p => (p.fullWidth ? fullWidthStyle : null)};
  color: ${({ theme, invert }) => (invert ? theme.textInvert : theme.text)};
`

function Markdown({
  source,
  noMargin,
  fullWidth,
  className,
  id,
  invert,
}: Props) {
  const theme = React.useContext(ThemeContext)

  return (
    <StyledMarkdown
      source={source}
      noMargin={noMargin}
      fullWidth={fullWidth}
      className={className}
      renderers={RENDERERS}
      disallowedTypes={['inlineCode', 'code']}
      id={id}
      invert={invert}
      theme={theme}
    />
  )
}

Markdown.displayName = 'Markdown'
Markdown.defaultProps = {}

export default Markdown
