// @flow
import * as React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

import type { MenuItemCategory } from '../../types/MenuItemCategory'

import { Link } from '../Link/Link'
import Heading from '../Heading/Heading'
import { getLineHeight } from '../../styles/style-helpers'
import { color } from '../../styles/theme'

type Props = MenuItemCategory & {
  negative?: boolean,
  keyName?: string,
  withBottomBorder?: boolean,
}

const MenuColumnItemWrapper = styled.div`
  padding-bottom: ${rem(16)};
  margin-bottom: ${rem(32)};
  flex: 0 0 31%;
  ${({ withBottomBorder }) =>
    withBottomBorder
      ? `
  border-bottom: 1px solid ${color.lola}
`
      : null}
`

const MenuColumnItemTitle = styled(Heading)`
  font-weight: 900;
`

const MenuColumnItemLinkList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`

const MenuColumnItemLinkListItem = styled.li`
  margin: 0 0 ${rem(8)} 0;
  display: inline-block;
`
export const Separator = styled.span`
  color: ${color.gumbo};
  margin: 0 ${rem(8)} 0 ${rem(8)};

  font-size: ${rem(14)};
`

const MenuColumnItemLink = styled(Link)`
  font-weight: 300;
  font-stretch: normal;
  font-size: ${rem(14)};
  line-height: ${getLineHeight('normal')};
  letter-spacing: normal;
  ${({ negative }) =>
    negative
      ? `
    color: ${color.white}
  `
      : null}
`

const MenuColumnItem = ({
  title,
  links,
  negative,
  keyName,
  withBottomBorder,
}: Props) => {
  return (
    <MenuColumnItemWrapper
      key={keyName || `MenuColumnItemWrapper}`}
      withBottomBorder={withBottomBorder}
    >
      {title && (
        <MenuColumnItemTitle invert={negative} tag="h2" level={4}>
          <Link {...title}>{title.label}</Link>
        </MenuColumnItemTitle>
      )}
      {links && (
        <MenuColumnItemLinkList>
          {links.map((link, idx) => (
            <MenuColumnItemLinkListItem key={`MenuItem-${idx}`}>
              <MenuColumnItemLink {...link} negative={negative}>
                {link.label}
              </MenuColumnItemLink>
              {idx + 1 !== links.length && <Separator>|</Separator>}
            </MenuColumnItemLinkListItem>
          ))}
        </MenuColumnItemLinkList>
      )}
    </MenuColumnItemWrapper>
  )
}
MenuColumnItem.displayName = 'MenuColumnItem'
MenuColumnItem.defaultProps = {}

export default MenuColumnItem
