// @flow
import * as React from 'react'

type Props = {
  children?: React.Node,
}

// this renderer resolved a bug in Internet Explorer 11.
// if supportsStringRender === true, it forces the returned string to be
// wrapper in a React Fragment to prevent the IE bug from happening

// detect IE8 and above, and Edge
// source: https://stackoverflow.com/questions/31757852/how-can-i-detect-internet-explorer-ie-and-microsoft-edge-using-javascript
const isIE = () =>
  typeof window !== 'undefined' &&
  (document.documentMode || /Edge/.test(navigator.userAgent))

var supportsStringRender =
  !isIE() && parseInt((React.version || '16').slice(0, 2), 10) >= 16

function TextRenderer(props: Props) {
  return supportsStringRender
    ? props.children
    : React.createElement('span', null, props.children)
}

TextRenderer.displayName = 'TextRenderer'
TextRenderer.defaultProps = {}

export default TextRenderer
