// @flow
import * as React from 'react'

const ArrowDownIcon = ({ style, ...rest }: Object) => (
  <svg
    viewBox="0 0 24 24"
    {...rest}
    width="1em"
    height="1em"
    style={{ cursor: 'pointer', ...style }}
  >
    <path
      fill="none"
      strokeMiterlimit={10}
      d="M2 7l10 10L22 7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      stroke="currentColor"
    />
  </svg>
)

export default ArrowDownIcon
