// @flow
import * as React from 'react'
import consecutive from 'consecutive'

import type { LinkViewModel } from '../../types/LinkViewModel'
import { AccordionContext } from './AccordionContainer'

import AccordionItemTitle from './AccordionItemTitle'
import AccordionItemBody from './AccordionItemBody'
import styled from 'styled-components'
import { color } from '../../styles/theme'

export type Props = {
  id?: string,
  disabled: boolean,
  title?: string,
  isArticle?: boolean,
  isLast?: boolean,
  cta?: LinkViewModel,
  children: React.Node,
  iconStyle?: Object,
  titleStyle?: Object,
  titleInnerStyle?: Object,
  itemBodyStyle?: Object,
  hoverShadow?: boolean,
  animateTitle?: boolean,
  invert?: boolean,
  isMenu?: boolean,
  isOnFooter?: boolean,
  prefix?: string,
}

type State = {
  isResting: boolean,
  expanded: boolean,
}

let nextUuid = consecutive()
export function resetNextUuid() {
  nextUuid = consecutive()
}

const Wrapper = styled.div`
  > :first-child {
    border-top: 0;
  }

  > :last-child {
    ${({ isArticle, theme, isOnFooter }) =>
      `border-bottom: ${
        isOnFooter
          ? 0
          : theme.name === 'lgbt' && !isArticle
          ? `1px solid ${color.grey}`
          : isArticle
          ? '1px solid #e6e6e6'
          : '1px solid #E3E3E3'
      };`}
  }
`

class AccordionItem extends React.Component<Props, State> {
  static contextType = AccordionContext
  static defaultProps = {
    disabled: false,
    expanded: false,
  }

  state = {
    isResting: true,
    expanded: false,
  }

  uuid = nextUuid()

  handleAnimating = () => {
    this.setState({
      isResting: false,
    })
  }

  handleResting = () => {
    this.setState({
      isResting: true,
    })
  }

  render() {
    const {
      children,
      title,
      cta,
      isArticle,
      isLast,
      iconStyle,
      titleStyle,
      titleInnerStyle,
      itemBodyStyle,
      hoverShadow,
      animateTitle,
      invert,
      isMenu,
      isOnFooter,
      prefix,
    } = this.props
    const idPrefix = prefix ? prefix : ''
    const keyGen = consecutive()
    const id = this.props.id || `accordion-${this.uuid}-${keyGen()}`
    const titleId = `${idPrefix}__acc__item-${id}-${keyGen()}`
    const bodyId = `${idPrefix}__acc__body-${id}-${keyGen()}`
    const expanded = this.context.selected.includes(id)

    return (
      <Wrapper isOnFooter={isOnFooter}>
        <AccordionItemTitle
          id={id}
          titleId={titleId}
          controlsId={bodyId}
          title={title}
          isArticle={isArticle}
          expanded={expanded}
          isLast={isLast}
          onToggle={this.context.handleToggle}
          isResting={this.state.isResting}
          iconStyle={iconStyle}
          titleStyle={titleStyle}
          titleInnerStyle={titleInnerStyle}
          hoverShadow={hoverShadow}
          animateTitle={animateTitle}
          invert={invert}
        />
        <AccordionItemBody
          isMenu={isMenu}
          invert={invert}
          id={bodyId}
          labeledById={titleId}
          expanded={expanded}
          isArticle={isArticle}
          isResting={this.state.isResting}
          cta={cta}
          onResting={this.handleResting}
          onStart={this.handleAnimating}
          itemBodyStyle={itemBodyStyle}
          hoverShadow={hoverShadow}
        >
          {children}
        </AccordionItemBody>
      </Wrapper>
    )
  }
}

export default AccordionItem
