// @flow
import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { CSSTransition } from 'react-transition-group'

import { color } from '../../../styles/theme'
import { durations } from '../../../styles/animations'

import type { MainMenuItem } from '../../../types/MainMenuItem'

import HeaderDesktopOverlay from '../HeaderDesktopOverlay/HeaderDesktopOverlay'
import { Link } from '../../../components/Link/Link'

type Props = {
  activeMenuKey?: number,
  isOpen?: boolean,
  onHover: Function,
  onClose: Function,
  closeLabel: string,
  itemKey: number,
  menuItem: MainMenuItem,
}

function HeaderDesktopMenuItem({
  activeMenuKey,
  isOpen,
  onClose,
  onHover,
  closeLabel,
  itemKey,
  menuItem,
}: Props) {
  const [hover, setHover] = useState(false)
  const [opening, setOpening] = useState(false)
  const buttonRef = useRef()

  useEffect(() => {
    const timer = setTimeout(() => {
      if (hover) {
        setOpening(true)
        onHover(itemKey)
      }
    }, 200)

    return () => clearTimeout(timer)
  }, [hover])

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpening(false)
    }, 500)

    return () => clearTimeout(timer)
  }, [opening])

  const { mainLink: link, column1 = [], column2 = [], column3 = [] } = menuItem
  if (
    !link ||
    !link.label ||
    (!link.href && !column1.length && !column2.length && !column3.length)
  )
    return null

  const isActive = itemKey === activeMenuKey
  const ariaControlId = `headerMenu-${itemKey}`
  const ariaLabeledById = `overlayMenu-${itemKey}`

  const closeMenu = shouldFocus => {
    if (isOpen && onClose) {
      // $FlowFixMe
      shouldFocus && buttonRef.current.focus()
      onClose()
    }
  }

  return !link.href ? (
    <>
      <ButtonWrapper>
        <div>
          <MenuButton
            key={itemKey}
            notLink
            isActive={isActive}
            onClick={() =>
              isOpen && !opening ? closeMenu(false) : onHover(itemKey)
            }
            aria-controls={ariaLabeledById}
            aria-expanded={isActive}
            id={ariaControlId}
            ref={buttonRef}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onFocus={() => !isActive && onClose()}
          >
            {link.label}
          </MenuButton>
        </div>
        <Border isActive={isActive} />
      </ButtonWrapper>
      <CSSTransition
        classNames="CT-Menu"
        in={!isNaN(activeMenuKey)}
        timeout={200}
        unmountOnExit
      >
        <HeaderDesktopOverlay
          onClose={closeMenu}
          menuItem={menuItem}
          closeLabel={closeLabel}
          id={`overlayMenu-${itemKey}`}
          ariaControlledBy={`headerMenu-${itemKey}`}
          isActive={itemKey === activeMenuKey}
        />
      </CSSTransition>
    </>
  ) : (
    <ButtonWrapper>
      <div>
        <HeaderLink {...link} onFocus={onClose} right={0}>
          {link.label}
        </HeaderLink>
      </div>
    </ButtonWrapper>
  )
}

export default HeaderDesktopMenuItem

export const HeaderLink = styled(Link)`
  margin-right: ${({ right }) => rem(right)};
  outline-offset: 4px;
  color: ${({ theme }) => theme.body};
`

const ButtonWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
`

const Border = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  transition: border-color ${durations.fast} ease-in-out;
  border-bottom: 2px solid;
  border-color: ${({ isActive }) => (isActive ? color.accent : 'transparent')};
`

const MenuButton = styled.button`
  padding: 0;
  display: block;
  border: none;
  text-decoration: none;
  cursor: default;
  user-select: none;
  font-family: ${({ theme }) => theme.fontFamily};
  outline-offset: 4px;
  color: ${({ theme }) => theme.logoTextColor};

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: ${({ theme }) => theme.logoTextColorHover};
  }

  &:focus {
    outline: 1px dashed currentColor;
  }

  :focus:not([data-focus-visible-added]) {
    outline: none;
  }
`
//TODO: Check why the styles below were not working in production!!
//font-size: ${({ theme }) =>
//    theme.name === 'kids' || theme.name === 'lgbt' ? rem(17) : rem(16)};
//  font-weight: ${({ theme }) =>
//    theme.name === 'kids' || theme.name === 'lgbt' ? 700 : 500};
