//@flow

export default function handleOpenSearch(link?: string) {
  try {
    window.raffleApi.open(link)
  } catch (e) {
    console.error(e)
    alert('Søgning er ikke tilgængelig på nuværende tidspunkt')
  }
}
