// @flow
import * as React from 'react'
import styled from 'styled-components'
import Modal from 'react-responsive-modal'
import { rem } from 'polished'

import { breakpoints, mediaQuery } from '../../styles/media'
import MediaQuery from '../MediaQuery/MediaQuery'
import RemoveIcon from '../../icons/RemoveIcon'

type Props = {
  children?: React.Node,
  open?: boolean,
  closeText?: string,
  onClose?: () => void,
}

const ModalContent = styled.div`
  padding-top: ${rem(8)};
  padding-bottom: ${rem(16)};

  ${mediaQuery.md`
    padding-top: ${rem(48)};
    padding-bottom: ${rem(48)};  
  `}
`

const ModalCloseWrapper = styled.div`
  text-align: right;

  ${mediaQuery.md`
    margin-right: -${rem(48)};  
  `}
`
const ModalCloseButton = styled.button`
  cursor: pointer;
  border: none;
  &:focus,
  &:focus-visible {
    outline: none;
    border: 1px dashed currentColor;
  }
`

const ModalCloseText = styled.span`
  margin-right: ${rem(8)};
  position: relative;
  top: ${rem(2)};
`

const ModalComponent = ({ onClose, children, open, closeText }: Props) => {
  const handleCloseModal = () => {
    onClose && onClose()
  }

  return (
    <React.Fragment>
      <MediaQuery query={`(min-width: ${breakpoints.md}em)`}>
        <Modal
          focusTrapped
          open={open}
          showCloseIcon={false}
          closeOnEsc
          center
          onClose={handleCloseModal}
          styles={{
            modal: {
              borderRadius: rem(4),
              padding: `${rem(32)} ${rem(96)} ${rem(80)}`,
              maxWidth: '80%',
            },
          }}
        >
          <ModalCloseWrapper>
            <ModalCloseButton onClick={handleCloseModal}>
              <ModalCloseText>{closeText || ''}</ModalCloseText>
              <RemoveIcon style={{ height: rem(20), width: rem(20) }} />
            </ModalCloseButton>
          </ModalCloseWrapper>
          <ModalContent>{children && children}</ModalContent>
        </Modal>
      </MediaQuery>
      <MediaQuery query={`(max-width: ${breakpoints.md}em)`}>
        <Modal
          open={open}
          showCloseIcon={false}
          closeOnEsc
          center
          onClose={handleCloseModal}
          styles={{
            modal: {
              borderRadius: rem(4),
              padding: `${rem(32)}`,
            },
          }}
        >
          <ModalCloseWrapper>
            <ModalCloseButton onClick={handleCloseModal}>
              <ModalCloseText>{closeText || ''}</ModalCloseText>
              <RemoveIcon style={{ height: rem(20), width: rem(20) }} />
            </ModalCloseButton>
          </ModalCloseWrapper>
          <ModalContent>{children && children}</ModalContent>
        </Modal>
      </MediaQuery>
    </React.Fragment>
  )
}

ModalComponent.displayName = 'Modal'
ModalComponent.defaultProps = {}
export default ModalComponent
