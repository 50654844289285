// @flow
import * as React from 'react'
import styled from 'styled-components'
import { rem, rgba } from 'polished'

import type { LinkViewModel } from '../../../types/LinkViewModel'

import { color } from '../../../styles/theme'
import Logo from '../../../components/Logo/Logo'
import MenuButton from '../MenuButton/MenuButton'
import Ribbon from '../../../components/Ribbon/Ribbon'

type Props = {
  /** The link to use on logo. Label will be the site name to display next to the logo */
  home?: LinkViewModel,
  siteName?: string,
  search?: LinkViewModel,
  isOpen?: boolean,
  onMenuClick: () => void,
}

const HeaderDeviceWrapper = styled.nav`
  z-index: 10;
  background-color: ${color.white};
  border-bottom: ${({ isOpen, theme }) =>
    !isOpen ? `1px solid ${rgba(theme.primary, 0.08)}` : 'none'};
`

const Bar = styled.div`
  height: ${rem(72)};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Column = styled.div`
  display: flex;
  align-items: center;
`

const HomeColumn = styled(Column)`
  height: ${rem(32)};
  justify-items: flex-start;
  margin-bottom: ${rem(8)};
`

const MenuColumn = styled(Column)`
  justify-items: flex-end;
`

// todo: icon does not align-right with Ribbon elements
const Menu = styled(MenuButton)`
  padding: 0;

  /* Specific margin for burger svg */
  margin-right: -15px;
`

function HeaderDevice({ home, siteName, onMenuClick, search, isOpen }: Props) {
  return (
    <HeaderDeviceWrapper isOpen={isOpen} aria-label="mobile menu">
      <Ribbon marginBottom={false}>
        <Bar>
          <HomeColumn>
            <Logo name={siteName} link={home} />
          </HomeColumn>
          <MenuColumn>
            <Menu
              onClick={onMenuClick}
              isOpen={isOpen}
              icon="burger"
              size={48}
            />
          </MenuColumn>
        </Bar>
      </Ribbon>
    </HeaderDeviceWrapper>
  )
}
HeaderDevice.displayName = 'HeaderDevice'
HeaderDevice.defaultProps = {}

export default HeaderDevice
