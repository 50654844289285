// @flow

import * as React from 'react'
import { rem } from 'polished'
import { color, effects } from '../../styles/theme'
import styled from 'styled-components'
import { breakpoints, mediaOnly, mediaQuery } from '../../styles/media'
import ArrowDownIcon from '../../icons/ArrowDownIcon'
import { durations } from '../../styles/animations'
import MediaQuery from '../MediaQuery/MediaQuery'
import { getFontSize } from '../../styles/style-helpers'

const AccordionTitle = styled.div`
  margin: 0;
  font-size: ${getFontSize('large')};
  user-select: none;
  font-weight: ${({ theme }) =>
    theme.name === 'kids' || theme.name === 'lgbt' ? 700 : 500};
  flex: 1 1 auto;
  transform-origin: left;
  color: ${({ theme, invert }) => (invert ? theme.textInvert : theme.primary)};

  &:hover {
    color: ${color.accent};
  }
`

const IconContainer = styled.div`
  color: ${({ expanded, theme, invert }) =>
    expanded ? color.accent : invert ? theme.textInvert : theme.primary};
  transition: ${durations.slow} ease-in-out;

  svg {
    transition: ${durations.fast} ease-in-out;
    transform: ${({ expanded }) =>
      expanded ? 'rotateX(180deg)' : 'rotateX(0deg)'};
    margin-right: ${rem(24)};

    ${mediaOnly.xs`
      margin-right: ${rem(8)};
    `};
  }
`

const OutLine = styled.div`
  content: '';
  position: absolute;
  height: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  ${mediaQuery.sm`
    left: 0;
    right: 0;
  `};
`

const ShadowBackdrop = styled.div`
  transition: box-shadow 0.2s;
  content: '';
  position: absolute;
  outline-offset: 1px;
  height: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  ${mediaQuery.sm`
    left: 0;
    right: 0;
  `};
`

const TitleWrapper = styled.h4`
  position: relative;
  padding: ${rem(30)} 0;
  margin-bottom: ${({ expanded }) => (expanded ? rem(8) : 0)};
  display: flex;
  justify-content: space-between;
  outline: none;
  transition: color ${durations.slow} ease-in-out;
  border-top: 1px solid;

  ${({ isArticle }) =>
    `border-top-color: ${isArticle ? '#e6e6e6' : '#E3E3E3'};`}

  ${/* sc-selector */ IconContainer} {
    color: ${({ theme }) => theme.primary};
  }

  &:hover {
    color: ${color.accent};

    ${/* sc-selector */ ShadowBackdrop} {
      box-shadow: ${p => (p.showShadow ? effects().cardShadow : null)};
    }

    ${/* sc-selector */ IconContainer} {
      color: ${({ theme }) => theme.primary};
    }
  }

  &:focus {
    outline: 1px dashed currentColor;
  }

  :focus:not([data-focus-visible-added]) {
    outline: none;
  }

  .js-focus-visible & :focus:not([data-focus-visible-added]) {
    ${/* sc-selector */ ShadowBackdrop} {
      outline: none;
    }
  }
`

type Props = {
  id: string,
  titleId: string,
  controlsId: string,
  children?: React.Node,
  title?: string,
  expanded: boolean,
  isArticle?: boolean,
  isResting?: boolean,
  isLast?: boolean,
  iconStyle?: Object,
  titleStyle?: Object,
  titleInnerStyle?: Object,
  hoverShadow?: boolean,
  animateTitle?: boolean,
  onToggle?: (id: string) => void,
  theme?: string,
  invert?: boolean,
}

class AccordionItemTitle extends React.PureComponent<Props> {
  static displayName = 'AccordionItemTitle'
  static defaultProps = { expanded: false, isLast: false }

  handlePreventDoubleClick = (event: SyntheticMouseEvent<HTMLDivElement>) => {
    if (event.detail > 1) {
      event.preventDefault()
    }
  }

  handleClick = () => {
    const { id, onToggle } = this.props
    if (onToggle) onToggle(id)
  }

  handleKeyPress = (evt: SyntheticKeyboardEvent<HTMLButtonElement>) => {
    if (evt.charCode === 13 || evt.charCode === 32) {
      evt.preventDefault()
      this.handleClick()
    }
  }

  render() {
    const {
      titleId,
      controlsId,
      expanded,
      title,
      children,
      isResting,
      isArticle,
      iconStyle,
      titleStyle,
      hoverShadow,
      invert,
    } = this.props

    const iconStyling = iconStyle || {}
    const titleStyling = titleStyle || {}
    const activeTheme = this.props.theme

    if (hoverShadow && !titleStyling.paddingLeft) {
      titleStyling.paddingLeft = rem(24)
    }

    return (
      <MediaQuery query={`(min-width: ${breakpoints.sm}em)`} defaultMatches>
        {isDesktop => (
          <TitleWrapper
            id={titleId}
            aria-expanded={expanded}
            aria-selected={expanded}
            aria-controls={controlsId}
            onClick={this.handleClick}
            onMouseDown={this.handlePreventDoubleClick}
            role="tab"
            tabIndex="0"
            onKeyPress={this.handleKeyPress}
            expanded={expanded}
            showShadow={hoverShadow && !expanded && isResting}
            style={titleStyling}
            theme={activeTheme && activeTheme}
          >
            {hoverShadow ? <ShadowBackdrop /> : <OutLine />}
            <AccordionTitle invert={invert} isArticle={isArticle}>
              {children || title}
            </AccordionTitle>
            <IconContainer expanded={expanded}>
              <ArrowDownIcon style={iconStyling} aria-hidden />
            </IconContainer>
          </TitleWrapper>
        )}
      </MediaQuery>
    )
  }
}

export default AccordionItemTitle
