// @flow
import * as React from 'react'
import { RouteContext } from '../../App/Router'

export type AccordionContextType = {
  handleToggle: (id: string) => void,
  selected: Array<string>,
}
export const AccordionContext = React.createContext<AccordionContextType>({
  handleToggle: () => {},
  selected: [],
})

type Props = {
  children: React.Node,
  initial?: string,
}

type State = {
  selected: Array<string>,
}

class AccordionContainer extends React.Component<Props, State> {
  static displayName = 'AccordionContainer'
  static contextType = RouteContext

  static hasId = (id: string, children: React.Node) => {
    return !!React.Children.toArray(children).find(child => {
      return child.props.id === id
    })
  }

  static hashToId = (hash: string) =>
    hash && hash.length > 1 ? hash.substring(1) : undefined

  currentHash: string = ''

  constructor(props: Props) {
    super(props)

    this.state = {
      selected: props.initial ? [props.initial] : [],
    }
  }

  componentDidMount(): void {
    const { hash } = this.context.location
    this.currentHash = hash

    const id = AccordionContainer.hashToId(hash)
    if (id && AccordionContainer.hasId(id, this.props.children)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ selected: [...this.state.selected, id] })
    }
  }

  componentDidUpdate(prevProps: Props): void {
    if (
      this.context.location.hash &&
      this.context.location.hash !== this.currentHash
    ) {
      const id = AccordionContainer.hashToId(this.context.location.hash)
      this.currentHash = this.context.location.hash

      if (
        id &&
        AccordionContainer.hasId(id, this.props.children) &&
        !this.state.selected.includes(id)
      ) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ selected: [...this.state.selected, id] })
      }
    }
  }

  handleToggle = (id: string) => {
    if (!id) return
    const { selected } = this.state
    if (selected.includes(id)) {
      this.setState({
        selected: this.state.selected.filter(value => value !== id),
      })
    } else {
      this.setState({ selected: [...this.state.selected, id] })
    }
  }

  render() {
    return (
      <AccordionContext.Provider
        value={{
          handleToggle: this.handleToggle,
          selected: this.state.selected,
        }}
      >
        <div aria-multiselectable role="tablist">
          {this.props.children}
        </div>
      </AccordionContext.Provider>
    )
  }
}

export default AccordionContainer
